<template>
    <div class="home">

        <div class="primary d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar">
            <v-row justify="space-around">
                <v-col cols="12" sm="12" md="1" lg="1">
                    <span class="white--text"></span>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" class="mt-2 mb-2">
                    <span class="white--text"><span class="white--text"><v-icon color="#FFF" small>mdi-phone</v-icon>
                            93843
                            57572</span></span>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                    <span class="white--text"></span>
                </v-col>
                <v-col cols="12" sm="12" md="1" lg="2">
                    <span class="white--text"></span>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="3" class="mt-2 mb-2">
                    <span class="white--text"><v-icon color="#FFF" small>mdi-email</v-icon>
                        kannancrackers2015@gmail.com</span>
                </v-col>
                <v-col cols="12" sm="12" md="1" lg="1">
                    <span class="white--text"></span>
                </v-col>
            </v-row>
        </div>

        <v-app-bar color="white" fixed height="125px" class="mt-md-8 mt-lg-8">
            <v-container>
                <v-row justify="space-around" id="container" ref="container" class="mt-5">
                    <v-col cols="12" md="6" lg="6" large class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <v-breadcrumbs :items="menus" divider=" ">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :href="item.href">
                                    <v-btn small color="secondary" class="mb-2">{{ item.text }}</v-btn>
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                    <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <v-breadcrumbs :items="menus"></v-breadcrumbs>
                    </div>
                    <v-col cols="12" sm="12" md="6" lg="3" class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <img alt="Vue logo" src="../assets/logo.png" height="60" contain />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="3" class="d-flex justify-space-between flex-row text-wrap">
                        <v-btn icon v-if="cartQty" class="mb-8" @click="popupCheckout">
                            <v-badge color="black" :content="cartQty" overlap offset-x="10" offset-y="10">
                                <v-icon color="black">mdi-cart-outline</v-icon>
                            </v-badge>
                        </v-btn>
                        <v-btn icon v-if="cartQty == ''" class="mb-8">
                            <v-badge color="black" content="0" overlap offset-x="10" offset-y="10" bordered>
                                <v-icon color="black">mdi-cart-outline</v-icon>
                            </v-badge>
                        </v-btn>
                        <v-btn text class="mb-8">
                            <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">NET TOTAL :</div> ₹ {{
                            productSalesPrice }}
                        </v-btn>
                        <v-btn outlined color="primary" @click="contactDialog = true"><v-icon color="primary" small
                                class="mr-2">mdi-phone</v-icon>Contacts</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>

        <v-dialog v-model="contactDialog" width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Contact Details
                </v-card-title>
                <v-card-text>
                    <p class="mb-1 mt-5"><v-icon color="primary" small>mdi-phone</v-icon> Order Confirmation : 93843
                        57572,
                        80569
                        03599, 73971 53601, 97504 44600, 75388 97572</p>
                    <p class="mb-1"><v-icon color="primary" small>mdi-phone</v-icon> Payment Confirmation: 97905 72926,
                        97900
                        39111,
                        97900 25111, 63854 27540, 63854 27539</p>
                    <p class="mb-1 mt-5"><v-icon color="primary" small>mdi-phone</v-icon> Dispatch:
                        96003317037548840091,7540091520,9597631302, 8870061845</p>
                    <p class="mb-1"><v-icon color="primary" small>mdi-phone</v-icon> Complaint:
                        9942449196,7540011846,7530027560,7530034370</p>
                    <p class="mb-1"><v-icon color="primary" small>mdi-phone</v-icon> Address Confirmation:
                        9790431702,8148251846</p>
                    <p class="mb-1"><v-icon color="primary" small>mdi-phone</v-icon> Transport L L R confirmation :
                        7708367545,9597681301</p>
                    <p class="mb-1"><v-icon color="primary" small>mdi-phone</v-icon> Owner Number :
                        9384755471</p>
                    <p class="mb-1"><v-icon color="primary" small>mdi-email</v-icon> kannancrackers2015@gmail.com</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="contactDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-container>
            <div class="productdiv" ref="productdiv1"></div>
            <v-card class="mt-1 mx-auto" height="150" elevation="0">
                <div class="d-flex flex-column fill-height justify-center align-center text-black">
                    <h1 class="primary--text">
                        {{ banner.title }}
                    </h1>
                </div>
            </v-card>

            <v-card class="mx-auto" elevation="0">
                <div class="d-flex flex-column fill-height justify-center align-center text-black">
                    <div v-html="banner.details" class="text-justify"></div>
                </div>
            </v-card>
        </v-container>




        <v-dialog v-model="errorDialog" max-width="590">
            <v-card>
                <v-card-title class="text-h5">

                </v-card-title>
                <v-card-text>
                    <v-alert text prominent type="error" v-if="errorResponse">
                        <div v-for="(error, i) in cartError.response" :key="i">
                            <h3 v-html="error"></h3>
                        </div>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="errorDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="checkoutDialog" persistent transition="dialog-top-transition" width="900">
            <v-card>
                <v-card-title class="text-h5 red darken-2 white--text"> Checkout </v-card-title>

                <v-card-text class="mt-5">
                    <v-list three-line>
                        <template>
                            <div v-for="(category, i) in products" :key="i" class="product-cart-div">
                                <div v-for="(product, i) in category.products" :key="i" class="product-cart-div">
                                    <v-list-item v-if="product.qty > 0">
                                        <v-card outlined class="mx-auto product-cart-card">
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="12" sm="10" md="10">
                                                        <v-list-item-subtitle
                                                            class="text--primary font-weight-bold font-label">
                                                            <v-row>
                                                                <v-col cols="12" sm="6" md="6">
                                                                    <h3 class="text-wrap ml-2"
                                                                        style="max-width: 25rem;">{{
                            product.name }} </h3>
                                                                </v-col>
                                                                <v-col cols="12" sm="6" md="6">
                                                                    <div
                                                                        class="d-flex justify-space-between flex-row text-wrap ml-2 mr-2">
                                                                        <h3>Rs. {{ product.salesprice }}</h3>
                                                                        <h3>Qty. {{ product.qty }} </h3>
                                                                        <h3>Rs. {{ product.totalSalesPrice }}</h3>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-subtitle>
                                                    </v-col>

                                                    <!--<v-col cols="12" sm="2" md="2">
                              <v-list-item-avatar size="80" tile>
                                <v-img :src="product.image"></v-img>
                              </v-list-item-avatar>
                            </v-col>-->
                                                </v-row>
                                            </v-list-item-content>

                                        </v-card>
                                    </v-list-item>
                                </div>
                            </div>
                        </template>
                    </v-list>
                </v-card-text>

                <v-card-text>

                    <!--alert box-->
                    <!-- <v-alert text prominent type="error" v-if="errorResponse">
              <div v-for="(error, i) in cartError.response" :key="i">
                <h3 v-html="error"></h3> 
              </div>
            </v-alert>-->
                    <!--alert box end-->

                    <!--alert box-->
                    <v-alert text prominent type="success" v-if="successResponse" class="mt-4">
                        <div>
                            {{ cartError }} <v-btn outlined @click="refereshPage()" color="primary"
                                class="ml-15">close</v-btn>
                        </div>
                    </v-alert>
                    <!--alert box end-->



                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <form class="mt-5" v-if="!successResponse">

                                <v-row>
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field v-model="mobileno" :error-messages="mobilenoErrors"
                                            label="Mobileno" required outlined @input="$v.mobileno.$touch()"
                                            @blur="$v.mobileno.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-btn class="ma-2 float-left" outlined color="primary" @click="sendOtp"
                                            width="150" height="40">
                                            <span class="black--text">GET OTP</span>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="mb-5">
                                        <h3 class="black--text mb-5 text--disabled">
                                            ENTER OTP HERE :
                                        </h3>
                                        <v-otp-input v-model="otp" type="number" :error-messages="otpErrors"
                                            @input="$v.otp.$touch()" @blur="$v.otp.$touch()"></v-otp-input>
                                    </v-col>
                                </v-row>
                                <v-text-field v-model="name" :error-messages="nameErrors" label="Name" required outlined
                                    @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
                                <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail" required
                                    outlined @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>



                                <v-text-field v-model="state" :error-messages="stateErrors" label="State" required
                                    outlined @input="$v.state.$touch()" @blur="$v.state.$touch()"></v-text-field>

                                <v-text-field v-model="deliverycity" :error-messages="deliverycityErrors"
                                    label="Delivery City" required outlined @input="$v.deliverycity.$touch()"
                                    @blur="$v.deliverycity.$touch()"></v-text-field>

                                <v-textarea v-model="address" label="Address" required outlined
                                    @input="$v.address.$touch()" @blur="$v.address.$touch()"
                                    :error-messages="addressErrors"></v-textarea>

                                <v-row justify="space-around" id="container" ref="container">
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productRate" outlined label="Rate"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productOfferPrice" outlined label="Offer Price"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productSalesPrice" outlined label="Sales Price"
                                            readonly></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-checkbox v-model="checkbox" :error-messages="checkboxErrors" label="Order Confirm?"
                                    required @change="$v.checkbox.$touch()" @blur="$v.checkbox.$touch()"></v-checkbox>
                                <v-divider></v-divider>
                                <v-btn class="mr-4 mt-5" @click="submitCheckout" outlined :disabled="chechoutbtn">
                                    submit
                                </v-btn>
                                <v-btn @click="checkoutDialog = false" color="primary" outlined class="mr-4 mt-5">
                                    close
                                </v-btn>
                            </form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <loading v-if="loading" />

        <v-container>
            <v-card class="mx-auto d-none" outlined>

                <v-card-text>
                    <!--alert box-->
                    <v-alert text prominent type="success" v-if="successResponse" class="mt-4">
                        <div>
                            {{ cartError }} <v-btn outlined @click="refereshPage()" color="primary"
                                class="ml-15">close</v-btn>
                        </div>
                    </v-alert>
                    <!--alert box end-->

                    <form class="mt-5" v-if="!successResponse">
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="mobileno" :error-messages="mobilenoErrors" label="Mobileno"
                                    required outlined @input="$v.mobileno.$touch()"
                                    @blur="$v.mobileno.$touch()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn class="ma-2 float-left" outlined color="primary" @click="sendOtp" width="150"
                                    height="40">
                                    <span class="black--text">GET OTP</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="4" md="4" class="mb-2">
                                <h3 class="black--text mb-5 text--disabled">
                                    ENTER OTP HERE :
                                </h3>
                                <v-otp-input v-model="otp" type="number" :error-messages="otpErrors"
                                    @input="$v.otp.$touch()" @blur="$v.otp.$touch()"></v-otp-input>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-around" id="container" ref="container" class="mt-2">
                            <v-col cols="12" sm="6" md="6">
                                <v-row justify="space-around" id="container" ref="container">
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="name" :error-messages="nameErrors" label="Name" required
                                            outlined @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail"
                                            required outlined @input="$v.email.$touch()"
                                            @blur="$v.email.$touch()"></v-text-field>
                                    </v-col>
                                    <!--<v-col cols="12" sm="4" md="4">
                      <v-text-field v-model="mobileno" :error-messages="mobilenoErrors" label="Mobileno" required outlined
                        @input="$v.mobileno.$touch()" @blur="$v.mobileno.$touch()"></v-text-field>
                    </v-col>-->
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="state" :error-messages="stateErrors" label="State"
                                            required outlined @input="$v.state.$touch()"
                                            @blur="$v.state.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="deliverycity" :error-messages="deliverycityErrors"
                                            label="Delivery City" required outlined @input="$v.deliverycity.$touch()"
                                            @blur="$v.deliverycity.$touch()"></v-text-field>
                                    </v-col>

                                </v-row>

                                <v-textarea v-model="address" label="Address" required outlined
                                    @input="$v.address.$touch()" @blur="$v.address.$touch()"
                                    :error-messages="addressErrors"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-row justify="space-around" id="container" ref="container">
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="productRate" outlined label="Rate"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="productOfferPrice" outlined label="Offer Price"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field v-model="productSalesPrice" outlined label="Sales Price"
                                            readonly></v-text-field>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>

                        <v-checkbox v-model="checkbox" :error-messages="checkboxErrors" label="Order Confirm?" required
                            @change="$v.checkbox.$touch()" @blur="$v.checkbox.$touch()"></v-checkbox>
                        <v-divider></v-divider>
                        <v-btn class="mr-4 mt-5" @click="submitCheckout" outlined :disabled="chechoutbtn">
                            submit
                        </v-btn>
                    </form>
                </v-card-text>
            </v-card>

        </v-container>

        <!--footer Component-->
        <div>
            <footerCommon />
        </div>

        <!--footer Component-->

        <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" elevation="24">
            {{ text }}
        </v-snackbar>
    </div>
</template>

<style scoped>
.top-bar {
    position: fixed;
    width: 100%;
    z-index: 111;
}

.v-application p {
    margin-bottom: 0px;
}

.qty-alt {
    border: 1px solid #ddd;
    width: 100%;
    text-align: center;
    font-weight: 500;
    height: 50px;
}

.qty-btn {
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.qty-textbox {
    max-width: 55%;
    text-align: center !important;
}

.qty-textbox .v-text-field {
    text-align: center !important;
}

.productdiv {
    margin-top: 165px !important;
}

.banner-text {
    line-height: 50PX;
}


.product-cart-div {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.product-cart-card {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import { required, email } from 'vuelidate/lib/validators'
//import store from "../store";




export default {
    name: "Aboutus",
    data: () => ({
        panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27],
        productRate: '0.00',
        productOfferPrice: '0.00',
        productSalesPrice: '0.00',
        cartQty: 0,
        checkoutDialog: false,
        name: '',
        email: '',
        mobileno: '',
        state: '',
        deliverycity: '',
        address: '',
        checkbox: false,
        chechoutbtn: false,
        errorDialog: false,
        contactDialog: false,
        imageDialog: false,
        clickedImage: '',
        otp: "",
        snackbar: false,
        text: 'OTP Sent Successfully',
        timeout: 2000,
        menus: [

            {
                text: 'Retail Price',
                disabled: false,
                href: '/',
            },
            {
                text: 'Family pack',
                disabled: false,
                href: 'familypack',
            },
            {
                text: 'Wholesale case',
                disabled: false,
                href: 'wholesale',
            },
            {
                text: 'Whole sale',
                disabled: false,
                href: 'diwali',
            },
            {
                text: 'Gift box',
                disabled: false,
                href: 'giftbox',
            },
            {
                text: 'About Us',
                disabled: false,
                href: 'aboutus',
            },
            {
                text: 'Contact Us',
                disabled: false,
                href: 'contactus',
            },
        ],

    }),
    validations: {
        name: { required },
        email: { required, email },
        mobileno: { required },
        state: { required },
        deliverycity: { required },
        address: { required },
        otp: { required },

        checkbox: {
            checked(val) {
                return val
            },
        },
    },
    computed: {
        ...mapGetters([
            "authStatus",
            "authStatusError",
            "authStatusresponse",
            "getProducts",
            "getBannerDetails",
            "getOrderStatus",
            "orderStatusResponse",
        ]),
        checkboxErrors() {
            const errors = []
            if (!this.$v.checkbox.$dirty) return errors
            !this.$v.checkbox.checked && errors.push('You must agree to continue!')
            return errors
        },
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('Name is required.')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Must be valid e-mail')
            !this.$v.email.required && errors.push('E-mail is required')
            return errors
        },
        mobilenoErrors() {
            const errors = []
            if (!this.$v.mobileno.$dirty) return errors
            !this.$v.mobileno.required && errors.push('Mobileno is required.')
            return errors
        },
        stateErrors() {
            const errors = []
            if (!this.$v.state.$dirty) return errors
            !this.$v.state.required && errors.push('State is required.')
            return errors
        },
        deliverycityErrors() {
            const errors = []
            if (!this.$v.deliverycity.$dirty) return errors
            !this.$v.deliverycity.required && errors.push('Delivery City is required.')
            return errors
        },
        addressErrors() {
            const errors = []
            if (!this.$v.address.$dirty) return errors
            !this.$v.address.required && errors.push('Address is required.')
            return errors
        },
        otpErrors() {
            const errors = [];
            if (!this.$v.otp.$dirty) return errors;
            !this.$v.otp.required && errors.push("OTP is required");
            return errors;
        },
        loading: function () {
            return this.authStatus === "loading";
        },
        products: function () {
            return this.getProducts;
        },
        banner: function () {
            return this.getBannerDetails;
        },

        errorResponse: function () {
            console.log(this.getOrderStatus);
            return this.getOrderStatus === "error";
        },
        successResponse: function () {
            console.log(this.getOrderStatus);
            return this.getOrderStatus === "success";
        },
        cartError: function () {
            return this.orderStatusResponse;
        },



    },
    created() {

        this.fetchProducts();
        this.fetchPageDetails('5');
        setTimeout(() => {
            this.allPanels();
        }, 2500);


        this.$store.subscribe((mutation) => {
            //console.log(mutation);
            if (mutation.type === "authError") {
                this.errorDialog = true;
            }

            if (mutation.type === "authRequest") {
                this.chechoutbtn = true;
            }

            if (mutation.type === "authRequest") {
                this.chechoutbtn = true;
            }

            if (mutation.type === "orderConfirmed" || mutation.type === "orderConfirmedError") {
                this.chechoutbtn = false;
            }
        });

    },
    methods: {
        ...mapActions([
            "fetchProducts",
            "fetchPageDetails",
            "orderConfirm",
            "otpAction",
        ]),
        allPanels() {
            this.panel = [...Array(this.products.length).keys()].map((k, i) => i)
            //console.log(this.products.length);
        },
        increment(product) {
            // store.commit("authRequest");
            product.qty = parseFloat(product.qty) + 1;
            product.totalRate = product.qty * product.rate;
            product.totalOfferPrice = product.qty * product.offerPrice;
            product.totalSalesPrice = product.qty * product.salesprice;
            this.calculateTotal();
        },
        decrement(product) {
            //  store.commit("authRequest");
            product.qty = product.qty === 0 ? 0 : parseFloat(product.qty) - 1;
            product.totalRate = product.qty * product.rate;
            product.totalOfferPrice = product.qty * product.offerPrice;
            product.totalSalesPrice = product.qty * product.salesprice;
            this.calculateTotal();
        },
        productQtyUpdate(product) {
            //console.log(product);
            // console.log(event.target.value);
            // store.commit("authRequest");
            //check event.target.value is number or not

            if (event.target.value) {
                product.qty = product.qty < 0 ? 0 : parseFloat(event.target.value);
                product.totalRate = product.qty * product.rate;
                product.totalOfferPrice = product.qty * product.offerPrice;
                product.totalSalesPrice = product.qty * product.salesprice;
                this.calculateTotal();
            }
            else {
                product.qty = 0;
                product.totalRate = product.qty * product.rate;
                product.totalOfferPrice = product.qty * product.offerPrice;
                product.totalSalesPrice = product.qty * product.salesprice;
                this.calculateTotal();
            }

        },
        calculateTotal() {

            var totalQtyCalc;
            totalQtyCalc = this.products.reduce(function (categorysum, product) {
                var catTotal = product.products.reduce(function (productsum, product) {
                    var qty = parseFloat(product.qty);
                    if (!isNaN(qty)) {
                        return productsum + qty;
                    }
                }, 0);
                if (!isNaN(catTotal)) {
                    return categorysum + catTotal;
                }

            }, 0);
            this.cartQty = totalQtyCalc;
            // console.log(totalQtyCalc);
            var totalRateCalc;
            totalRateCalc = this.products.reduce(function (categorysum, product) {
                var catTotal = product.products.reduce(function (productsum, product) {
                    var totalRate = parseFloat(product.totalRate);
                    if (!isNaN(totalRate)) {
                        return productsum + totalRate;
                    }
                }, 0);
                if (!isNaN(catTotal)) {
                    return categorysum + catTotal;
                }

            }, 0);

            //console.log(totalRateCalc);

            this.productRate = totalRateCalc.toFixed(2);

            var totalOfferPriceCalc;
            totalOfferPriceCalc = this.products.reduce(function (categorysum, product) {
                var catTotal = product.products.reduce(function (productsum, product) {
                    var totalOfferPrice = parseFloat(product.totalOfferPrice);
                    if (!isNaN(totalOfferPrice)) {
                        return productsum + totalOfferPrice;
                    }
                }, 0);
                if (!isNaN(catTotal)) {
                    return categorysum + catTotal;
                }

            }, 0);

            //console.log(totalOfferPriceCalc);
            this.productOfferPrice = totalOfferPriceCalc.toFixed(2);

            var totalSalesPriceCalc;
            totalSalesPriceCalc = this.products.reduce(function (categorysum, product) {
                var catTotal = product.products.reduce(function (productsum, product) {
                    var totalSalesPrice = parseFloat(product.totalSalesPrice);
                    if (!isNaN(totalSalesPrice)) {
                        return productsum + totalSalesPrice;
                    }
                }, 0);
                if (!isNaN(catTotal)) {
                    return categorysum + catTotal;
                }

            }, 0);

            // console.log(totalSalesPriceCalc);

            this.productSalesPrice = totalSalesPriceCalc.toFixed(2);

        },
        refereshPage() {
            window.location.reload()
        },
        popupCheckout() {

            this.checkoutDialog = true;
        },
        sendOtp() {
            //this.$v.$touch();
            if (this.$v.mobileno.$invalid) {
                this.$v.mobileno.$touch();
                console.log(this.mobileno);
                console.log("ERROR");
            } else {
                // do your submit logic here
                setTimeout(() => {
                    const loginData = {
                        mobileno: this.mobileno,
                    };

                    this.otpAction(loginData);

                    this.snackbar = true;
                    this.chechoutbtn = false;
                    // this.checkoutBtnShow = false;
                }, 500);
            }
        },
        submitCheckout() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                console.log("ERROR");
            } else {

                //this.chechoutbtn=true;
                let orderDetails = [];
                this.products.forEach(function (productlist) {

                    productlist.products.forEach(function (product) {
                        // console.log(product);
                        if (parseFloat(product.qty) > 0) {
                            orderDetails.push(product);
                        }
                    });
                });


                // do your submit logic here
                setTimeout(() => {
                    const orderData = {
                        name: this.name,
                        email: this.email,
                        mobileno: this.mobileno,
                        otp: this.otp,
                        state: this.state,
                        deliverycity: this.deliverycity,
                        address: this.address,
                        productRate: this.productRate,
                        productOfferPrice: this.productOfferPrice,
                        productSalesPrice: this.productSalesPrice,
                        cartQty: this.cartQty,
                        orderDetails: orderDetails,

                    };
                    //console.log(orderData);
                    this.orderConfirm(orderData);
                    //this.chechoutbtn=false;
                }, 500);
            }
        },
        imageDialogOpen(image) {
            console.log(image);
            this.clickedImage = image;
            this.imageDialog = true;

        },


    },

};
</script>